<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="60px"
              max-width="60px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

<!--            <h2 class="text-2xl font-weight-semibold">-->
<!--              {{ appName }}-->
<!--            </h2>-->
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t("Reset Password") }} 🔒
          </p>
          <p class="mb-2">
            {{ $t("Your new password must be different from previously used passwords") }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
              ref="resetPasswordForm"
              @submit.prevent="handleFormSubmit"
          >

            <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :error-messages="errorMessages.password"
                :rules="[validators.required, validators.passwordValidator]"
                label="New Password"
                placeholder=""
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline "
                hide-details="auto"
                class="mb-3"
                @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
                v-model="confirmPassword"
                outlined
                :type="isConfirmPasswordVisible ? 'text' : 'password'"
                :error-messages="errorMessages.passwordConfirm"
                :rules="[validators.required, validators.passwordValidator, validators.confirmedValidator(password, confirmPassword)]"
                label="Confirm Password"
                placeholder=""
                :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                hide-details="auto"
                @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
            ></v-text-field>

            <v-btn
                block
                color="primary"
                class="mt-4"
                type="submit"
            >
              Set New Password
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center mt-2">
          <router-link
              :to="{name:'auth-login'}"
              class="d-flex align-center text-sm"
          >
            <v-icon
                size="24"
                color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import {getCurrentInstance, ref} from '@vue/composition-api'
import themeConfig from '@themeConfig'
import {useRouter} from "@core/utils";
import {confirmedValidator, passwordValidator, required} from "@core/utils/validation";
import axios from "@axios";
export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const resetPasswordForm = ref(null)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const { router } = useRouter()

    const handleFormSubmit = () => {
      const isFormValid = resetPasswordForm.value.validate()

      if (!isFormValid) return

      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');
      const token = urlParams.get('token');

      axios
          .post('/auth/new-password', {email, token, password: password.value, password_confirmation: confirmPassword.value})
          .then(resp => {
            if(resp.data === true) {
              vm.$notify({
                group: 'deals',
                title: 'password successfully recovered',
                type: 'success',
                text: 'you can sign in using your new password',
                max: 5
              })
              router.push({name: 'auth-login'})
            }
          }).catch(err => {
        vm.$notify({
          group: 'deals',
          title: 'Reset password error',
          type: 'error',
          text: vm.$t(err.response.data.message),
          max: 5
        })
      })
    }
    return {
      handleFormSubmit,
      errorMessages,
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      validators: {
        required,
        passwordValidator,
        confirmedValidator,
      },

      resetPasswordForm
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
